/* eslint-disable max-len */
import React from 'react'
import foundsData from '../../../assets/data/inter-asset/fundos.json'
import * as S from './style'

import slugify from 'slugify'
import { ICategoria } from '../../../templates/fundosListados/types'

const ConhecaNossosFundos = () => {
  const cards: ICategoria[] = foundsData

  return (
    <S.SectionWrapper id='conheca-nossos-fundos' className='py-5 bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-gray-750 f-sora fw-600 mb-4'>
              Conheça nossos produtos
            </h2>
          </div>
          <div className='col-12'>
            <div className='card-container d-flex flex-wrap'>
              {cards.map((card: ICategoria) => (
                <S.Card className='d-flex flex-column justify-content-between' key={slugify(card.primeiroNome, card.segundoNome)}>
                  <div className=''>
                    { card.segundoNome === 'Carteira Inteligente' && <span className='fs-14 lh-17 fs-md-16 lh-md-20 fw-400 f-inter text-gray-650 d-block mb-1'>Fundos</span>}
                    <h3
                      className='fs-20 lh-25 fs-md-24 lh-md-30 f-sora fw-400 text-interasset-brand mb-2'
                      dangerouslySetInnerHTML={{ __html: card.segundoNome }}
                    />
                    <p
                      className='fs-12 lh-15 fs-md-14 lh-md-17 fw-400 f-inter fw-400 text-gray-650 mb-0'
                      dangerouslySetInnerHTML={{ __html: card.descricao }}
                    />
                  </div>
                  <div>
                    <S.Button as='a' href={`/tipos-de-fundos/?category=${card.segundoNome}`} className='fs-10 lh-12 fw-700 text-orange-medium'>Acessar</S.Button>
                  </div>
                </S.Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default ConhecaNossosFundos
