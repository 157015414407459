import styled from 'styled-components'
import { gray, white } from '../../../styles/colors'
import Img from 'gatsby-image'
import breakpoints from '../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background: ${gray[400]};

  h2 {
    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 32px;
    }
  }


  .react-multi-carousel-dot {
    button {
      width: 8px !important;
      height: 5px !important;
      border-radius: 16px;
      transition: .3s ease-in-out;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 16px !important;
      height: 5px !important;
      border-radius: 16px;
    }
  }

  .persona-container {
    @media (min-width: ${breakpoints.md}) {
      gap: 24px;
    }
    @media (min-width: ${breakpoints.xl}) {
      gap: 40px;
    }
  }
`

export const PersonaPhoto = styled(Img)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;

  @media (min-width: ${breakpoints.md}) {
    width: 160px;
    height: 160px;
  }
`

export const Contact = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #B6B7BB;

  svg {
    width: 32px;
    height: 32px;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 64px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`
