/* eslint-disable max-len */
import React from 'react'
import data from './data.json'
import queryData from '../../pageQuery'
import * as S from './style'

interface IPersona {
  image: {
    src: string;
    alt: string;
  };
  name: string;
}

const UmTimeDeEspecialistasComVoce = () => {
  const personas: IPersona[] = data
  const image = queryData()
  return (
    <S.SectionWrapper id='principios-que-geram-resultados' className='py-5 bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 pb-2'>
            <h2 className='fs-24 lh-30 fs-xl-32 lh-xl-40 text-gray-750 f-sora fw-600 mb-3 mb-lg-4'>
              Um time de especialistas com você
            </h2>
            <p className='fs-14 lh-17 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-750 mb-0'>
              Somos uma empresa orientada pelas necessidade dos nossos clientes e trabalhamos de forma independente, sempre alinhados com seus interesses em busca dos melhores resultados no longo prazo.
            </p>
          </div>
          <div className='col-12'>
            <div className='d-flex flex-wrap justify-content-between justify-content-md-start persona-container'>
              {personas.map((persona: IPersona) => (
                <div key={persona.name} className=''>
                  <S.PersonaPhoto fluid={image[persona.image.src].fluid} alt={persona.image.alt} className='mb-2 mx-auto' />
                  <span className='fs-12 lh-15 fs-md-14 lh-md-17 d-block text-center'>{persona.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default UmTimeDeEspecialistasComVoce
