import styled from 'styled-components'
import { gray, white } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background: ${white};

  h2 {
    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 32px;
    }
  }


  .react-multi-carousel-dot {
    button {
      width: 8px !important;
      height: 5px !important;
      border-radius: 16px;
      transition: .3s ease-in-out;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 16px !important;
      height: 5px !important;
      border-radius: 16px;
    }
  }

  .card-container {
    @media (min-width: ${breakpoints.md}) {
      gap: 24px;
    }
  }
 `

export const Card = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${gray[400]};
  border: 1px solid ${gray[550]};
  height: 351px;

  @media (min-width: ${breakpoints.md}) {
    width: 332px;
    height: 186px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 452px;
    height: 170px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 552px;
  }

  svg {
    width: 24px;
    height: 24px;
    @media (min-width: ${breakpoints.lg}) {
      width: 32px;
      height: 32px;
    }
  }
`

export const Button = styled.div`
  width: 100%;
  height: 32px;
  border: 1px solid #FFB46E;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`
