import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      processosEstrategicosImagem: imageSharp(fluid: { originalName: { regex: "/processos-estrategicos-imagem/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoDanielCastro: imageSharp(fluid: { originalName: { regex: "/photo-daniel-castro/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoMarceloMMattos: imageSharp(fluid: { originalName: { regex: "/photo-marcelo-m-mattos/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoMarianneMoraes: imageSharp(fluid: { originalName: { regex: "/photo-marianne-moraes/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoIanLima: imageSharp(fluid: { originalName: { regex: "/photo-ian-lima/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoDanielViana: imageSharp(fluid: { originalName: { regex: "/photo-daniel-viana/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoRafaelCosta: imageSharp(fluid: { originalName: { regex: "/photo-rafael-costa/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      photoMauroLima: imageSharp(fluid: { originalName: { regex: "/photo-mauro-lima/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
