import React from 'react'

import IntercoIcons from '../../../components/IntercoIcons'
import DefaultCarousel from '../../../components/UI/Carousels/DefaultCarousel'

import useWidthResize from '../../../hooks/useWidth'

import data from './data.json'
import * as S from './style'

interface ICard {
  title: string;
  description: string;
}

const PrincipiosQueGeramResultados = () => {
  const cards: ICard[] = data
  const windowWidth = useWidthResize(300)
  const WIDTH_MD = 768
  return (
    <S.SectionWrapper id='principios-que-geram-resultados' className='py-5 bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-gray-750 f-sora fw-600'>
              Princípios que geram resultados
            </h2>
          </div>
          <div className='col-12'>
            {(windowWidth < WIDTH_MD) ? (
              <DefaultCarousel
                sm={{ items: 2 }}
                md={{ items: 6 }}
                lg={{ items: 6 }}
                xl={{ items: 6 }}
              >
                {cards.map((card: ICard) => (
                  <div key={card.title} className='px-1'>
                    <Card card={card} />
                  </div>
                ))}
              </DefaultCarousel>
            ) : (
              <div className='card-container d-flex flex-wrap'>
                {cards.map((card: ICard) => (
                  <Card card={card} key={card.title} />
                ))}
              </div>
            )}

          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

const Card = ({ card }: { card: ICard }) => {
  return (
    <S.Card className='d-lg-flex flex-row justify-content-between'>
      <div className='mb-3 mb-lg-0 mr-lg-3'>
        <IntercoIcons icon='check-circle' size='MD' color='#FF7A00' />
      </div>
      <div className=''>
        <h3
          className='fs-16 lh-20 fs-lg-20 lh-lg-25 f-sora fw-400 text-interasset-brand mb-2 mb-lg-3'
          dangerouslySetInnerHTML={{ __html: card.title }}
        />
        <p
          className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-md-14 lh-md-17 fw-400 f-inter fw-400 text-gray-750 mb-0'
          dangerouslySetInnerHTML={{ __html: card.description }}
        />
      </div>
    </S.Card>
  )
}

export default PrincipiosQueGeramResultados
