import styled from 'styled-components'
import { gray } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;

  .react-multi-carousel-dot {
    button {
      width: 8px !important;
      height: 5px !important;
      border-radius: 16px;
      transition: .3s ease-in-out;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 16px !important;
      height: 5px !important;
      border-radius: 16px;
    }
  }
 `

export const Card = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  color: #6A6C72;
  height: 236px;
  border: 1px solid ${gray[550]};

  @media (min-width: ${breakpoints.lg}) {
    height: 268px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 230px;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`
