import styled from 'styled-components'
import { gray, white } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background: ${white};


  .react-multi-carousel-dot {
    button {
      width: 8px !important;
      height: 5px !important;
      border-radius: 16px;
      transition: .3s ease-in-out;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 16px !important;
      height: 5px !important;
      border-radius: 16px;
    }
  }

  .card-container {
    gap: 16px;
    @media (min-width: ${breakpoints.md}) {
      gap: 24px;
    }
  }
 `

export const Card = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  color: #6A6C72;
  padding: 16px;
  height: 236px;
  border: 1px solid ${gray[550]};
  width: 100%;
  min-height: 188px;

  box-shadow: 0px 8px 16px 2px #16161614;

  @media (min-width: ${breakpoints.md}) {
    width: 332px;
    min-height: 200px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 452px;
    min-height: 200px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 360px;
    min-height: 200px;
  }

  p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const Button = styled.div`
  width: 100%;
  height: 32px;
  border: 1px solid #FFB46E;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`
