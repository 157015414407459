import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import breakpoints from '../styles/breakpoints'
import { LogoLink } from '../components/Header/style'
import { MenuOption, MenuBurger } from '../components/Menu/style'
import { gray, white } from '../styles/colors'

export const CustomHeader = css`
  ${MenuOption} {
    @media (min-width: ${breakpoints.lg}) {
      color: ${white};
      
      .arrow svg {
        fill: ${white};
      }
    }
  }

  ${MenuBurger} {

    span {
      background-color: ${white};
    }
  }
`

export const NossoTime = styled.section`

  @media(min-width: ${breakpoints.xl}) {
    min-height: 768px;
    position: relative;

    .text {
      position: relative;
    }

    .image {
      overflow: hidden;
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 615px;
    }
  }
`

export const NossosPrincipios = styled(BackgroundImage)`
  background-size: cover;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  img {
    margin-right: 15px;
    width: 24px !important;
    height: 24px;
  }

  p {
    font-size: 16px;
    color: ${gray[600]};
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
  }
`

export const BoxCard = styled.div`
  margin-left: auto;

  div {
    border-radius: 8px;
  }

  button {
    border: none;
    background: none;
    padding: 0;
    outline: none;
  }
`
