/* eslint-disable max-len */
import React from 'react'
import IntercoIcons from '../../../components/IntercoIcons'
import NewsletterSignupForm from '../../../components/Forms/NewsletterSignupForm'
import * as S from './style'

interface IPersona {
  image: {
    src: string;
    alt: string;
  };
  name: string;
}

const RecebaNossaNewsletter = () => {
  React.useEffect(() => {
    if (window.RDStationForms) {
      new window.RDStationForms('newsletter-site-duplicado-87db4ae21bc9135bac87', 'UA-17915712-21').createForm()
    }
  }, [])
  return (
    <S.SectionWrapper id='receba-nossa-newsletter' className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row justify-content-lg-center'>
          <div className='col-12'>
            <div role='main' id='newsletter-site-duplicado-87db4ae21bc9135bac87' />
          </div>
          <div className='col-12'>
            <h3 className='fs-20 lh-25 text-gray-750 fw-600 f-sora mb-3'>Contato</h3>
            <S.Contact className='d-flex justify-content-center justify-content-md-start align-items-center'>
              <IntercoIcons icon='email' size='SM' className='svg-pdf' color='#6A6C72' />
              <div className='d-md-flex ml-3'>
                <span className='fs-14 lh-17 text-gray-750 f-inter d-block mb-1 mb-md-0 mr-md-2'>Você pode mandar um e-mail para:</span>
                <a href='mailto:ri@interasset.com.br' className='fs-16 lh-20 fw-600 text-orange-medium f-sora d-block mb-0'>ri@interasset.com.br</a>
              </div>
            </S.Contact>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default RecebaNossaNewsletter
