/* eslint-disable max-len */
import React from 'react'

// Components
import Layout from '../components/Layout'
import SEO from '../components/Seo'

// Sections
import Hero from './_sections/_hero'
import Vantagens from './_sections/_vantagens'
import ConhecaNossosFundos from './_sections/_conheca-nossos-fundos'
import PrincipiosQueGeramResultados from './_sections/_principios-que-geram-resultados'
import UmTimeDeEspecialistasComVoce from './_sections/_um-time-de-especialistas-com-você'
import RecebaNossaNewsletter from './_sections/_receba-nossa-newsletter'
import AcessoAInformacao from './_sections/_acesso-a-informacao'

import disclaimerData from '../assets/data/disclaimer/data.json'

// Styles
import { CustomHeader } from './style'

const IndexPage = () => {
  return (
    <Layout customHeaderStyle={CustomHeader}>
      <SEO title='Inter Asset - Gestão de Investimentos Inteligente' description='A Inter Asset oferece soluções inteligentes para a gestão de investimentos, com solidez e gerenciamento de riscos em um portfólio estratégico.' />
      <Hero />
      <Vantagens />
      <ConhecaNossosFundos />
      <PrincipiosQueGeramResultados />
      <UmTimeDeEspecialistasComVoce />
      <RecebaNossaNewsletter />
      <AcessoAInformacao disclaimer={disclaimerData.home} />
    </Layout>
  )
}

export default IndexPage
